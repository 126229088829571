import { environment } from '@environments/environment';

export const apiKeyEndpoints = [
  {
    method: 'POST',
    endpoint: 'auth/login',
  },
];

export const apiKeyRequests = () =>
  apiKeyEndpoints.map(request => ({
    ...request,
    endpoint: `${environment.api.url}/${request.endpoint}/`,
  }));

export const isApiRequest = (url: string): boolean => {
  return url.startsWith(environment.api.url);
};

export const isApiKeyRequest = (
  url: string,
  method: string = 'GET'
): boolean => {
  console.log(apiKeyRequests());
  return !!apiKeyRequests().find(
    request => request.endpoint.includes(url) && request.method == method
  );
};
