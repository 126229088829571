import { ModuleWithProviders, NgModule } from '@angular/core';
import { PolicyService } from './policy.service';


@NgModule()
export class PolicyModule {
  static forRoot(): ModuleWithProviders<PolicyModule> {
    return {
      ngModule: PolicyModule,
      providers: [PolicyService],
    };
  }
}
