import {
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/abstract-services/auth/auth.service';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isApiKeyRequest, isApiRequest } from './api-request.filter';

export function authInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const authService: AuthService = inject(AuthService);
  const authorizedRequest = Boolean(
    request.headers.get('Authorization')
  );

  // Si la petición ya contiene cabeceras AUTH no hace falta añadirlas
  // Dejamos pasar peticiones que no apunten a back.

  if (authorizedRequest || !isApiRequest(request.url)) {
    return next(request);
  }

  const haveTokens = !!authService?.tokens?.accessToken;
  const apiKeyRequest = isApiKeyRequest(request.url, request.method);

  const authValue =
    !apiKeyRequest && haveTokens ?
      `Bearer ${authService.tokens.accessToken}`
    : `ApiKey ${environment.api.key}`;

  const authHeader = {
    setHeaders: {
      Authorization: authValue,
    },
  };

  request = request.clone(authHeader);

  return next(request).pipe(
    catchError(error => {
      const { status } = error.error;
      if (status === 401 && error.url.includes('auth'))
        authService.createError('auth.invalid.token');
      return throwError(() => error);
    })
  );
}
